import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Spin, notification } from 'antd'
import { connect } from 'react-redux'
import { createApiClient } from '@oneblinktech-org/helios-base';
import { send, favorite, plus } from '../fakeData/svgFiles'
import { dateFormat } from '../helpers/dateFormat'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import {
  OutlinedButton,
  FilledBrightButton,
} from '@oneblinktech-org/helios-base'
import skelUserImage from '../assets/images/skel-user.png';
import { Select } from 'antd'
import { NotificationManager } from 'react-notifications';
import { ReactComponent as Twitter } from "../assets/images/x-social.svg"
import { ReactComponent as Facebook } from "../assets/images/facebook.svg"
import { ReactComponent as LinkedIn } from "../assets/images/linkedin.svg"
import SocialIcons from '../components/SocialIcons'

const apiUrl = process.env.REACT_APP_API_URL;
const { get } = createApiClient({ apiUrl });
const libraries = ['places']
const containerStyle = {
  width: '100%',
  height: '90px',
}


const GroupHome = () => {

  const { id } = useParams()
  const history = useHistory();
  const token = localStorage.getItem('h-access_token')
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [nextMeeting, setNextMeeting] = useState({})
  const [isFutureMeeting, setIsFutureMeeting] = useState(false)
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState('')
  const [api, contextHolder] = notification.useNotification()
  const mapRef = useRef()
  const mapLoaded = useRef(false)
  const today = new Date();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  })

  useEffect(() => {
    if (data)
      document.title = `Group Home: ${data?.name}`
    else
      document.title = `Group Home`
  }, [data])

  useEffect(() => {
  const fetchData = async () => {
    try {
      if (id != '' && id != null) {
        try {
          const result = await get('/ithaca/groups/' + id)
          setData(result)
          setLoading(false)
        } catch (error) {
          api['error']({
            message: 'Failed',
            description:
              'Please try again later.',
          })
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  fetchData();
}, [id])

  useEffect(() => {
    if (data?.event_count > 0) {
      setEvents(data?.events?.map(i => { return { label: dateFormat(i.start_datetime, 'EST_LONGDATE_FORMAT'), value: i.register_link } }))
      let currentDate = new Date()
      let futureDates = data.events.filter(
        item => new Date(item.start_datetime) >= currentDate)

      let nearestDateObj = futureDates.reduce((nearest, item) => {
        let itemDate = new Date(item.start_datetime)
        let diffCurrent = Math.abs(currentDate - itemDate)
        let diffNearest = nearest ? Math.abs(
          currentDate - new Date(nearest.start_datetime)) : Infinity

        return diffCurrent < diffNearest ? item : nearest
      }, null)
      setNextMeeting(nearestDateObj)
    } else
      setEvents([])
  }, [data])

  useEffect(() => {
    const meetingDate = new Date(nextMeeting);
    setIsFutureMeeting(meetingDate > today);
    setSelectedEvent(nextMeeting?.register_link)
  }, [nextMeeting?.start_datetime]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
  }, [])

  return (
    <Spin spinning={loading}>
      <div className="container mw-100">
        {contextHolder}
        <div className="d-flex flex-column pb-5">
          <Link to="/" style={{ textDecoration: 'none' }}>&lt; Back to map</Link>
        {/*ADD THIS BACK AFTER COVER IMAGE IS BACK.*/}
        {/*<div*/}
        {/*  className="d-flex flex-row justify-content-between align-items-center mb-4">*/}
        {/*  <h6 className="tool-title">Group Home Page</h6>*/}
        {/*</div>*/}
          <div className="group">
          {/*THE COVER IMAGE IS COMING BACK SOON, AFTER WE BUILD THAT PART*/}
          {/*<div className="cover-image">*/}
          {/*  <label className="file_uploader"> </label> {*/}
          {/*  data?.profile_image_filename ?*/}
          {/*    <img className="cover-upload" src={data?.profile_image_filename}/>*/}
          {/*    :*/}
          {/*    <img className="cover-upload"*/}
          {/*         src={require('../assets/images/cover-upload.png').default}/>*/}
          {/*}*/}
          {/*</div>*/}
            <div
              className="information d-flex flex-column flex-md-row align-items-center justify-content-between mt-5 mt-md-3">
              {/*BE SURE TO ADD THE PADDING BACK TO THIS ELEMENT IN _content.scss*/}
              <div
                className="d-flex flex-column justify-content-center align-items-center align-items-md-start">
                <span className="name">{data?.name}</span> <span>Franchise: {data?.organization?.name}</span>
              </div>
              <div
                className="d-none d-md-flex flex-column flex-md-row align-items-center justify-content-center mt-4 mt-md-0">
                <div
                  className="d-flex flex-column justify-content-center align-items-center mr-0 mr-md-5">
                  <span className="report-title">Members</span>
                  <span>{data?.member_count}</span>
                </div>
              </div>
            </div>
            <div className="welcome_next-meeting">
              <div className="d-flex flex-column">
                <div
                  dangerouslySetInnerHTML={{ __html: data?.welcome_message }}></div>
                <div
                  className="d-flex flex-column flex-md-row justify-content-between mt-auto">
                  {/* { token &&
                  <OutlinedButton svgFile={plus} title="Invite Guest"
                                clickEvent={(e) => {}}
                                className="second_out_btn invite_btn mt-3 mt-md-0 justify-content-center"/>
                } */}
                  <FilledBrightButton title="Apply to Group"
                    clickEvent={(e) => {
                      e.preventDefault();
                      // Send a message to the parent or top-level window
                      window.top.location.href = data?.joinGroupLink;
                    }}
                    className="second_fill_btn apply_btn justify-content-center mt-4 mt-md-0 ml-0" />
                </div>
              </div>
              <div className="group d-flex flex-column gap-3">
                <div className='flex gap-3 align-items-center' >
                  <h1 className="card_title">Next meeting:</h1>
                  {events?.length > 0 &&
                    <Select
                      defaultValue={selectedEvent}
                      placeholder="Choose meeting"
                      style={{ width: 210 }}
                      options={events}
                      onChange={(e) => setSelectedEvent(e)}
                      key={selectedEvent}
                    >
                    </Select>
                  }
                </div>
                {events && events?.length > 0 ? (
                  <>
                    <div
                      className="mt-1 d-flex flex-row justify-content-start align-items-center mr-0 mr-md-5">
                      <span className="report-title d-none d-md-block font-weight-bolder">Location</span>
                      <span className="ml-4">
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="9" height="12"
                          viewBox="0 0 9 12" fill="none"><g
                            clip-path="url(#a)"><path
                              d="M5.87.2A4.873 4.873 0 0 0 4.495 0C3.12 0 1.882.58 1.053 1.497l2.125 1.66L5.87.2Z"
                              fill="#1A73E8" /><path
                              d="M1.053 1.497A4.016 4.016 0 0 0 0 4.182c0 .789.166 1.424.449 1.995l2.73-3.02-2.126-1.66Z"
                              fill="#EA4335" /><path
                              d="M4.506 2.585c.955 0 1.726.716 1.726 1.605 0 .39-.156.753-.41 1.034 0 0 1.356-1.506 2.682-2.966C7.958 1.278 7.012.535 5.87.2L3.18 3.156c.321-.345.79-.571 1.326-.571Z"
                              fill="#4285F4" /><path
                              d="M4.506 5.787c-.956 0-1.726-.717-1.726-1.606 0-.39.146-.753.4-1.025L.45 6.176c.467.962 1.247 1.742 2.047 2.713l3.325-3.674c-.322.354-.79.572-1.316.572Z"
                              fill="#FBBC04" /><path
                              d="M5.763 9.905C7.264 7.719 9.01 6.73 9.01 4.191c0-.699-.186-1.352-.507-1.932l-6.007 6.63c.254.309.517.662.77 1.025.917 1.315.663 2.095 1.249 2.095.585 0 .331-.789 1.248-2.104Z"
                              fill="#34A853" /></g><defs><clipPath
                                id="a"><path fill="#fff"
                                  d="M0 0h9v12H0z" /></clipPath></defs></svg>
                        {nextMeeting ? nextMeeting.location : ''}
                      </span>
                    </div>
                    <div className="mb-2">
                        {nextMeeting?.location_coordinates && (() => {
                            const coordinates = JSON.parse(nextMeeting.location_coordinates)?.coordinates;
                            const lat = coordinates?.lat;
                            const lng = coordinates?.lng;
                            if (lat !== undefined && lng !== undefined) {
                                return (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={{ lat: lat, lng: lng }}
                                        onLoad={onMapLoad}
                                        zoom={20}
                                    >
                                        <Marker position={{ lat: lat, lng: lng }} />
                                    </GoogleMap>
                                );
                            } else {
                                console.error("Latitude or Longitude is undefined.");
                                return null; // Or you can return a fallback message or component
                            }
                        })()}
                    </div>
                    <div
                      className="d-flex flex-column flex-md-row justify-content-between mt-auto">
                      <FilledBrightButton title="Register for Event"
                        clickEvent={(e) => {
                          if (selectedEvent) {
                            e.preventDefault();
                            if (token) {
                              history.push({
                                pathname: '/event-register',
                                state: { selectedEvent: selectedEvent }
                              });
                            } else {
                              // Send a message to the parent or top-level window
                              window.top.location.href = selectedEvent;
                            }
                          } else {
                            NotificationManager.error('Choose meeting', 'Error', 5000);
                          }
                        }}
                        className="second_fill_btn apply_btn justify-content-center mt-4 mt-md-0 ml-0" />
                    </div>
                  </>
                ) :
                  (data?.chairs != null && data?.chairs.length) > 0 ?
                    <span>Please contact the group chair {data?.chairs[0]?.firstname} {data?.chairs[0]?.lastname} at {data?.chairs[0]?.main_phone} to schedule a visit.</span>
                    :
                    <span>Please contact one of the members to schedule a visit.</span>
                }
              </div>
            </div>
            {
              (data?.chairs != null && data?.chairs.length) > 0 &&
              <div className="group_chair">
                <h1 className="mb-2">Group Chair{data?.chairs && data.chairs.length > 1 ? 's' : ''}</h1>
                <div className="pt-3">
                  {
                    data?.chairs?.sort((a, b) => a.lastname.localeCompare(b.lastname)).map(chairItem => {
                      return (
                        <div className="card card_over">
                          <div
                            className="head d-flex flex-column flex-sm-row align-items-start justify-content-between">
                            <div
                              className="info d-flex flex-column flex-sm-row align-items-start justify-content-between">
                              <div className="avatar-wrap">
                                <img className="avatar" src={chairItem?.full_profile_image_url} />
                              </div>
                              <div
                                className="d-flex flex-column align-items-center align-items-sm-start justify-content-center justify-content-sm-start">
                                <p className="mb-1 p-0 name">{chairItem?.firstname} {chairItem?.lastname}</p>
                                <p className="mb-1 p-0 label inverted">{chairItem?.user_setting?.business_name}</p>
                                <p className="mb-1 p-0 label inverted">Joined: {dateFormat(chairItem?.member_since, 'USER_LOGIN_DATE_FORMAT')}</p>
                              </div>
                            </div>
                            <div
                              className="referral d-flex flex-column align-items-center justify-content-between">
                              {
                                token &&
                                <Link to={`/sendReferral?groupId=${id}&memberId=${chairItem.id}`}>
                                  <button className="send">Send Referral</button>
                                </Link>
                              }
                              <div className='social'>
                                <SocialIcons
                                  linkedin={chairItem?.user_setting?.linkedin}
                                  facebook={chairItem?.user_setting?.facebook}
                                  twitter={chairItem?.user_setting?.twitter}
                                  fill={'var(--white)'}
                                  publicFields={chairItem?.user_setting?.public_fields}
                                  parentClassName='social-icon'
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="content d-flex flex-row align-items-start justify-content-between">
                            <div className="detail">
                              {token && (chairItem?.address || chairItem.city || chairItem.zipecode || chairItem.state) &&
                                <div>
                                  <img src={require('../assets/images/location.svg').default} />
                                  <span>
                                    {token && (chairItem?.address ?
                                      chairItem?.address + ', '
                                      : '')
                                    }
                                    {chairItem?.city
                                      ? chairItem?.city + ', '
                                      : ''} {chairItem?.zipecode ? chairItem?.zipecode +
                                        ', ' : ''} {chairItem?.state} </span>
                                </div>
                              }
                              {token && chairItem?.main_phone &&
                                <div>
                                  <img src={require('../assets/images/phone.svg').default} />
                                  <span>{chairItem?.main_phone}</span>
                                </div>
                              }
                              {chairItem?.email && chairItem?.user_setting?.public_fields?.includes('email') &&
                                <div>
                                  <img src={require('../assets/images/email.svg').default} />
                                  <span>{chairItem?.email}</span>
                                </div>
                              }
                              {chairItem?.user_setting?.profession &&
                                <div>
                                  <img src={require('../assets/images/work.svg').default} />
                                  <span>{chairItem?.user_setting?.profession}</span>
                                </div>
                              }
                            </div>
                            <div className="title d-flex flex-row align-items-center">
                              <img src={require('../assets/images/group-chair.svg').default} />
                              <span className="ml-2">Group Chair</span>
                            </div>
                          </div>
                          {token &&
                            <div className="footer d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                              <div className="d-flex flex-column flex-md-row align-items-center justify-content-between wrap">
                              {/*<FilledBrightButton svgFile={send}*/}
                              {/*                    title="Send Message"*/}
                              {/*                    clickEvent={(e) => {}}*/}
                              {/*                    className="primary_fill_btn justify-content-center"/>*/}
                              {/*<OutlinedButton svgFile={favorite}*/}
                              {/*                title="Give Gratitude"*/}
                              {/*                clickEvent={(e) => {}}*/}
                              {/*                className="primary_out_btn mt-3 mt-md-0 ml-md-3 justify-content-center"/>*/}
                            </div>
                            {/* {
                              chairItem?.user_setting?.calendly &&
                              <div
                                className="d-flex flex-column schedule mt-4 mt-md-0">
                                <span>Schedule meeting</span> <span>with <img
                                src={require(
                                  '../assets/images/calendly.png').default}/></span>
                              </div>
                            } */}
                            </div>
                          }
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            } {/*<div className='functionalities d-flex flex-column flex-md-row align-items-center justify-content-between'>*/} {/*    <div className='search-wrap w-100 px-3'>*/} {/*        <div className='d-flex flex-row align-items-center'>*/} {/*            <img className='d-inline-flex mr-2' src={require('../assets/images/search.svg').default} alt='' />*/} {/*            <input placeholder='Search member...    member name, location or a company ..' className='w-100' value='' />*/} {/*        </div>*/} {/*    </div>*/} {/*    /!* <div className='d-flex flex-row align-items-center justify-content-between'>*/} {/*        <div className='export d-flex align-items-center mr-4'>*/} {/*            <img src={require('../assets/images/export.svg').default} />*/} {/*            <span className='ml-1'>Export</span>*/} {/*        </div>*/} {/*        <FilledBrightButton*/} {/*            title="Send Message to All"*/} {/*            clickEvent={(e) => {}}*/} {/*            className="primary_fill_btn justify-content-center"*/} {/*        />*/} {/*    </div> *!/*/} {/*</div>*/}
            <div className="group_chair members">
              <h1 className="mb-2">Members</h1>
              <div className="pt-3">
                {data?.member_count > 0 &&
                  data?.members?.filter(member => !data?.chairs.some(chair => chair.uuid === member.uuid))?.sort((a, b) => a.lastname.localeCompare(b.lastname)).map(member => {
                    return (
                      <div className="card card_over">
                        <div className="head out-bg d-flex flex-column flex-sm-row align-items-start justify-content-between">
                          <div className="info d-flex flex-column flex-sm-row align-items-start justify-content-between">
                            <div className="avatar-wrap">
                              <img className="avatar" src={member?.full_profile_image_url ? member?.full_profile_image_url : skelUserImage} />
                            </div>
                            <div className="d-flex flex-column align-items-center align-items-sm-start justify-content-center justify-content-sm-start">
                              <p className="mb-1 p-0 name">{member?.firstname} {member?.lastname}</p>
                              <p className="mb-1 p-0 label">{member?.user_setting?.business_name}</p>
                              <p className="mb-1 p-0 label">Joined: {dateFormat(member?.member_since, 'USER_LOGIN_DATE_FORMAT')}</p>
                            </div>
                          </div>
                          <div className="referral d-flex flex-column align-items-center justify-content-between">
                            {token &&
                              <Link to={`/sendReferral?groupId=${id}&memberId=${member.id}`}>
                                <button className="send">Send Referral</button>
                              </Link>
                            }
                            <div className='social'>
                                <SocialIcons
                                    linkedin={member?.user_setting?.linkedin?.trim() || null}
                                    facebook={member?.user_setting?.facebook?.trim() || null}
                                    twitter={member?.user_setting?.twitter?.trim() || null}
                                    calendly={member?.user_setting?.calendly?.trim() || null}
                                    fill={'var(--trustegrity)'}
                                    publicFields={member?.user_setting?.public_fields|| null}
                                    parentClassName='social-icon'
                                />
                            </div>
                          </div>
                        </div>
                        <div className="content d-flex flex-row align-items-start justify-content-between">
                          <div className="detail">
                            {token && (member?.address || member.city || member.zipecode || member.state) &&
                              <div>
                                <img src={require('../assets/images/location.svg').default} />
                                <span>
                                  {token && (member?.address
                                      ? member?.address + ', '
                                      : '')
                                  }
                                  {member?.city
                                    ? member?.city + ', '
                                    : ''}{member?.zipecode
                                      ? member?.zipecode + ', '
                                    : ''}{member?.state}
                                </span>
                              </div>
                            }
                            {token && member?.main_phone &&
                              <div>
                                <img src={require('../assets/images/phone.svg').default} />
                                <span>{member?.main_phone}</span>
                              </div>
                            }
                            {member?.email && member?.user_setting?.public_fields?.includes('email') &&
                              <div>
                                <img src={require('../assets/images/email.svg').default} />
                                <span>{member?.email}</span>
                              </div>
                            }
                            {member?.user_setting?.profession &&
                              <div>
                                <img src={require('../assets/images/work.svg').default} />
                                <span>{member?.user_setting?.profession}</span>
                              </div>
                            }
                          </div>
                        </div>
                        {token &&
                          <div
                            className="footer d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                            <div
                              className="d-flex flex-column flex-md-row align-items-center justify-content-between wrap">
                              {/*<FilledBrightButton svgFile={send}*/}
                              {/*                    title="Send Message"*/}
                              {/*                    clickEvent={(e) => {}}*/}
                              {/*                    className="primary_fill_btn justify-content-center"/>*/}
                              {/*<OutlinedButton svgFile={favorite}*/}
                              {/*                title="Give Gratitude"*/}
                              {/*                clickEvent={(e) => {}}*/}
                              {/*                className="primary_out_btn mt-3 mt-md-0 ml-md-3 justify-content-center"/>*/}
                            </div>
                            {/* {
                              member?.user_setting?.calendly &&
                              <div
                                className="d-flex flex-column schedule mt-4 mt-md-0">
                                <span>Schedule meeting</span> <span>with <img
                                src={require(
                                  '../assets/images/calendly.png').default}/></span>
                              </div>
                            } */}
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="welcome_next-meeting">
            <div className="d-flex flex-column">
              <div
                className="d-flex flex-column flex-md-row justify-content-between mt-auto">
                {/* { token &&
                <OutlinedButton svgFile={plus} title="Invite Guest"
                                clickEvent={(e) => {}}
                                className="second_out_btn invite_btn mt-3 mt-md-0 justify-content-center"/>
              } */}
                <FilledBrightButton title="Apply to Group"
                  clickEvent={(e) => {
                    e.preventDefault();
                    window.top.location.href = data?.joinGroupLink;
                  }}
                  className="second_fill_btn apply_btn justify-content-center mt-4 mt-md-0 ml-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GroupHome)